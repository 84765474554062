import React, { useState, useRef, useEffect } from "react";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer";
import Container from "./Container";
import Personal from "../../components/Personal";
import Residence from "../../components/Residence";
import Employment from "../../components/Employment";
import Spouse from "../../components/Spouse";
import Beneficiary from "../../components/Beneficiary";
// import BankingReference from "../../components/BankReference";
import IncomeBalanceSheet, {
  getNumber,
} from "../../components/IncomeBalanceSheet";
import Objectives from "../../components/Objectives";
import Uploads from "../../components/Uploads";
import Corporation from "../../components/Corporation";
import Summary from "../../components/Summary";
import Loader from "../../components/Utills/Loader";
import {
  getClient,
  updateBalanceSheetInfo,
  updateBeneficiaryInfo,
  updateCorporationInfo,
  updateEmploymentInfo,
  updateObjectivesInfo,
  updatePersonalInfo,
  updateResidenceInfo,
  updateSpouseInfo,
  updateUploadInfo,
} from "../../backend/client";

const atleastOnePhone = {
  message: "Please add at least one phone number.",
  rule: (val, params) => {
    return val ? true : false;
  },
  required: true,
};

export default function InvestmentAccountForm() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    personal: {},
    employment: {
      country: "CA",
    },
    beneficiary: {
      // beneficiarySpouseEstate: [{ relationship: "", percent: "" }],
      // beneficiaryChildrenOther: [{ relationship: "", percent: "" }],
      // dependants: 0,
      account_spouse: "Spouse",
      account_holder: "Spouse",
    },
    // bankingReference: {},
    incomeBalanceSheet: {
      assets: [{ type: "", amount: "" }],
      liabilities: [{ type: "", amount: "" }],
    },
    objectives: {},
    residence: {
      m_country: "CA",
      country: "CA",
    },
    spouse: {},
    uploads: {},
    corporation: { corp_investment: false },
    dummy: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    getClient().then((res) => {
      if (res?.client?.id) {
        const {
          balance_sheet,
          // banking_reference,
          asset,
          beneficiary,
          // beneficiary_children,
          // beneficiary_estate,
          corporation,
          employment,
          liability,
          objectives,
          personal,
          residence,
          spouse,
          uploads,
          current_step,
        } = res.client;
        const ress = residence ? JSON.parse(residence) : null;
        const spo = spouse ? JSON.parse(spouse) : null;
        // is_married
        const updatedObj = {
          ...data,
          personal: personal ? JSON.parse(personal) : data.personal,
          employment: employment
            ? { ...JSON.parse(employment), ...data.employment }
            : data.employment,
          beneficiary: beneficiary ? JSON.parse(beneficiary) : data.beneficiary,
          objectives: objectives ? JSON.parse(objectives) : data.objectives,
          residence: ress
            ? {
                ...ress,
                ...data.residence,
                is_same:
                  ress.is_same != null || ress.is_same != undefined
                    ? ress.is_same
                    : true,
              }
            : data.residence,
          spouse: spo
            ? {
                ...spo,
                ...data.spouse,
                is_married:
                  spo.is_married != null || spo.is_married != undefined
                    ? spo.is_married
                    : true,
              }
            : data.spouse,
          uploads: uploads ? { files: JSON.parse(uploads) } : data.uploads,
          corporation: corporation
            ? { ...JSON.parse(corporation), ...data.corporation }
            : data.corporation,
          incomeBalanceSheet: balance_sheet
            ? {
                ...JSON.parse(balance_sheet),
                ...data.incomeBalanceSheet,
              }
            : data.incomeBalanceSheet,
        };
        const balanceSheet = updatedObj.incomeBalanceSheet;
        if (asset) {
          balanceSheet.assets = JSON.parse(asset);
        }
        if (liability) {
          balanceSheet.liabilities = JSON.parse(liability);
        }
        updatedObj.incomeBalanceSheet = balanceSheet;
        setData(updatedObj);

        if (current_step) {
          if (current_step == "completed") {
            navigate("/thankyou");
          } else if (current_step) {
            let c = steps.find((s) => s.step == current_step);
            setCurrentStep(c);
          }
        }
      }
      setIsLoading(false);
    });
  }, []);

  const validationMsgs = {
    messages: {
      required: "Required!",
    },
    element: (message) => {
      let msg = message;
      if (msg.includes("sin")) {
        msg = "The SIN must be 9 digits.";
      }
      return <div>{msg}</div>;
    },
  };

  const personalDataValidator = useRef(
    new SimpleReactValidator({
      ...validationMsgs,
      validators: {
        atleastOnePhone,
      },
    }),
  );
  const residenceDataValidator = useRef(
    new SimpleReactValidator(validationMsgs),
  );
  const employmentDataValidator = useRef(
    new SimpleReactValidator(validationMsgs),
  );
  const spouseDataValidator = useRef(
    new SimpleReactValidator({
      ...validationMsgs,
      validators: {
        atleastOnePhone,
      },
    }),
  );
  const corporationDataValidator = useRef(
    new SimpleReactValidator(validationMsgs),
  );
  const beneficiaryDataValidator = useRef(
    new SimpleReactValidator({
      ...validationMsgs,
      validators: {
        percentage: {
          message: "% should not be greater than 100",
          rule: (val, params) => {
            return Number(val) <= 100 ? true : false;
          },
          required: true,
        },
      },
    }),
  );
  // const bankingDataValidator = useRef(
  //     new SimpleReactValidator(validationMsgs)
  // );
  const incomeBalanceSheetDataValidator = useRef(
    new SimpleReactValidator(validationMsgs),
  );

  const steps = [
    {
      step: 1,
      title: "Personal",
      desc: "Please fill in your personal information below",
      id: "personal",
      comp: (handleChange, data) => {
        return (
          <Personal
            handleChange={(e) => {
              handleChange(e, "personal");
              personalDataValidator.current.purgeFields();
            }}
            data={data.personal}
            validator={personalDataValidator}
          />
        );
      },
      validator: personalDataValidator,
      steps: ["1", "2", "3", "4", "10"],
      saveData: (d) => updatePersonalInfo(d),
    },
    {
      step: 2,
      title: "Residence",
      desc: "Please fill in your primary residence information below",
      id: "residence",
      validator: residenceDataValidator,
      comp: (handleChange, data) => {
        return (
          <Residence
            handleChange={(e, objValue) => {
              handleChange(e, "residence", objValue);
              residenceDataValidator.current.purgeFields();
            }}
            data={data.residence}
            validator={residenceDataValidator}
          />
        );
      },
      steps: ["", "2", "3", "4", "10"],
      saveData: (d) => updateResidenceInfo(d),
    },
    {
      step: 3,
      title: "Employment",
      desc: "Please fill in your employment information below",
      id: "employment",
      validator: employmentDataValidator,
      comp: (handleChange, data) => {
        return (
          <Employment
            handleChange={(e) => {
              handleChange(e, "employment");
              employmentDataValidator.current.purgeFields();
            }}
            data={data.employment}
            validator={employmentDataValidator}
          />
        );
      },
      steps: ["", "", "3", "4", "10"],
      saveData: (d) => updateEmploymentInfo(d),
    },
    {
      step: 4,
      title: "Spouse",
      desc: "Please fill in your spouse's information below",
      id: "spouse",
      validator: spouseDataValidator,
      comp: (handleChange, data) => {
        return (
          <Spouse
            handleChange={(e) => {
              handleChange(e, "spouse");
              spouseDataValidator.current.purgeFields();
            }}
            data={data.spouse}
            validator={spouseDataValidator}
          />
        );
      },
      steps: ["", "", "4", "5", "10"],
      saveData: (d) => {
        let obj = d;
        if (!obj.is_married) {
          for (const key in obj) {
            obj[key] = "";
          }
        }
        obj.is_married = false;
        return updateSpouseInfo(obj);
      },
    },

    {
      step: 5,
      title: "Beneficiary",
      desc: "Please fill in your beneficiary information below",
      id: "beneficiary",
      validator: beneficiaryDataValidator,
      comp: (handleChange, data) => {
        return (
          <Beneficiary
            handleChange={(e) => {
              handleChange(e, "beneficiary");
              beneficiaryDataValidator.current.purgeFields();
            }}
            data={data.beneficiary}
            validator={beneficiaryDataValidator}
            isMarried={data?.spouse?.is_married ?? false}
          />
        );
      },
      steps: ["", "", "5", "6", "10"],
      saveData: (d) => updateBeneficiaryInfo(d),
    },
    // {
    // 	step: 7,
    // 	title: "Banking Reference",
    // 	desc: "Please fill in your banking information details below",
    // 	id: "bankingreference",
    // 	validator: bankingDataValidator,
    // 	comp: (handleChange, data) => {
    // 		return (
    // 			<BankingReference handleChange={(e) => handleChange(e, "bankingReference")} data={data.bankingReference} validator={bankingDataValidator} />
    // 		);
    // 	},
    // 	steps: ["", "", "7", "8", "11"],
    // },
    {
      step: 6,
      title: "Annual Income & Balance Sheet",
      desc: "Please fill in your income and balance sheet details below",
      id: "incomeBalanceSheet",
      validator: incomeBalanceSheetDataValidator,
      comp: (handleChange, data) => {
        return (
          <IncomeBalanceSheet
            handleChange={(e) => {
              handleChange(e, "incomeBalanceSheet");
              incomeBalanceSheetDataValidator.current.purgeFields();
            }}
            data={data.incomeBalanceSheet}
            validator={incomeBalanceSheetDataValidator}
            isMarried={data?.spouse?.is_married ?? false}
          />
        );
      },
      steps: ["", "", "6", "7", "10"],
      saveData: (d) => updateBalanceSheetInfo({ ...d, balance_sheet_id: d.id }),
    },
    {
      step: 7,
      title: "Corporation",
      desc: "Please fill in your corporation's information below",
      id: "corporation",
      validator: corporationDataValidator,
      comp: (handleChange, data) => {
        return (
          <Corporation
            handleChange={(e) => {
              handleChange(e, "corporation");
              corporationDataValidator.current.purgeFields();
            }}
            data={data.corporation}
            validator={corporationDataValidator}
          />
        );
      },
      steps: ["", "", "7", "8", "10"],
      saveData: (d) => {
        if (d.corp_investment) {
          return updateCorporationInfo({ ...d, corporateId: d.id });
        } else {
          return new Promise((resolve) => {
            resolve({ success: true });
          });
        }
      },
    },
    {
      step: 8,
      title: "Objectives",
      desc: "Please fill in the objective information below",
      id: "objectives",
      comp: (handleChange, data) => {
        return (
          <Objectives
            handleChange={(e) => handleChange(e, "objectives")}
            data={data.objectives}
          />
        );
      },
      steps: ["", "", "8", "9", "10"],
      saveData: (d) => updateObjectivesInfo(d),
    },
    {
      step: 9,
      title: "Uploads",
      desc: "Please upload the required files below",
      id: "uploads",
      comp: (handleChange, data) => {
        return (
          <Uploads
            handleChange={(e) => handleChange(e, "uploads")}
            data={data.uploads}
          />
        );
      },
      steps: ["", "", "9", "10"],
      saveData: (d) => updateUploadInfo(d),
    },
    {
      step: 10,
      title: "Submit",
      desc: "Check over your answers below before submitting.",
      id: "Submit",
      comp: () => {
        return <Summary data={data} />;
      },
      steps: ["", "", "", "", "10"],
    },
  ];

  const [currentStep, setCurrentStep] = useState(steps[0]);
  const onClickNext = () => {
    if (
      !currentStep?.validator ||
      currentStep?.validator?.current?.allValid()
    ) {
      if (currentStep.saveData) {
        setIsLoading(true);
        let dataToSave = data[currentStep.id];
        if (currentStep.id == "personal") {
          dataToSave.current_step = currentStep.step + 1;
        }
        currentStep
          .saveData(dataToSave)
          .then(async (res) => {
            setIsLoading(false);
            if (res.success) {
              if (currentStep.id != "personal") {
                await updatePersonalInfo({
                  current_step: currentStep.step + 1,
                });
              }
              let c = steps.find((s) => s.step === currentStep.step + 1);
              setCurrentStep(c);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err", err);
            swal("Oops!", err.error?.message, "error");
          });
      }
    } else {
      currentStep.validator.current.showMessages();
      setData({ ...data, dummy: data.dummy++ });
    }
  };

  const onClickPrev = () => {
    if (currentStep.step !== 1) {
      let c = steps.find((s) => s.step === currentStep.step - 1);
      setCurrentStep(c);
    }
  };

  const handleChange = ({ target: { name, value } }, obj, objValues) => {
    let updatedObj = { ...data[obj], [name]: value };
    let updatedData = { ...data, [obj]: updatedObj };
    if (objValues) {
      updatedData = { ...data, [obj]: { ...updatedObj, ...objValues } };
    } else {
      // if (
      //     obj === "personal" &&
      //     (name === "first_name" || name === "last_name")
      // ) {
      //     updatedData = {
      //         ...updatedData,
      //         beneficiary: {
      //             ...updatedData.beneficiary,
      //             account_holder: `${updatedData.personal.first_name} ${updatedData.personal.last_name}`,
      //         },
      //     };
      // } else
      if (obj === "spouse") {
        if (name === "is_married") {
          if (value) {
            updatedData = {
              ...updatedData,
              beneficiary: {
                ...updatedData.beneficiary,
                account_spouse: "Spouse",
                account_holder: "Spouse",
              },
            };
          } else {
            updatedData = {
              ...updatedData,
              beneficiary: {
                ...updatedData.beneficiary,
                account_spouse: "",
                account_holder:
                  updatedData.beneficiary.account_holder === "Spouce"
                    ? "Estate"
                    : updatedData.beneficiary.account_holder,
              },
            };
          }
        }
      } else if (obj === "incomeBalanceSheet") {
        let data = updatedData.incomeBalanceSheet;
        if (name === "total_assets" || name === "total_liabilities") {
          let equity =
            Number(data?.total_assets?.replaceAll(",", "") ?? 0) -
            Number(data?.total_liabilities?.replaceAll(",", "") ?? 0);
          equity = getNumber(equity + "");
          if (equity !== 0) {
            equity = equity.toLocaleString();
          } else {
            equity = "";
          }

          let dept = Number(
            data.total_liabilities && equity
              ? (Number(data.total_liabilities.replaceAll(",", "")) /
                  Number(equity.replaceAll(",", ""))) *
                  100
              : 0,
          ).toFixed(2);

          updatedData = {
            ...updatedData,
            incomeBalanceSheet: {
              ...data,
              equity,
              dept,
            },
          };
        } else if (name === "income" || name === "spouse_income") {
          updatedData = {
            ...updatedData,
            incomeBalanceSheet: {
              ...data,
              [name]: value.replaceAll(",", ""),
              household_income:
                Number(data?.income?.replaceAll(",", "") ?? 0) +
                Number(data?.spouse_income?.replaceAll(",", "") ?? 0),
            },
          };
        }
      } else if (obj === "objectives") {
        if (name === "investment_exp_none" && value) {
          updatedData = {
            ...updatedData,
            objectives: {
              ...updatedData.objectives,
              investment_exp_stocks: false,
              investment_exp_bonds: false,
              investment_exp_mutual_funds: false,
            },
          };
        } else if (
          name.includes("investexperience") &&
          !name.includes("None")
        ) {
          updatedData = {
            ...updatedData,
            objectives: {
              ...updatedData.objectives,
              investment_exp_none: false,
            },
          };
        }
      } else if (obj === "beneficiary") {
        if (name === "account_holder") {
          if (value === "Estate" || value === "Please discuss with me") {
            updatedData = {
              ...updatedData,
              beneficiary: {
                ...updatedData.beneficiary,
                account_spouse: "",
              },
            };
          } else {
            updatedData = {
              ...updatedData,
              beneficiary: {
                ...updatedData.beneficiary,
                account_spouse: "Spouse",
              },
            };
          }
        }
      }
    }

    setData(updatedData);
  };

  const onClickSave = async () => {
    await updatePersonalInfo({ current_step: "completed" });
    navigate("/thankyou");
  };

  const onClickCancel = () => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        navigate("/welcome");
      }
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Container
        currentStep={currentStep}
        personalNotes={data.personal?.notes ?? ""}
      >
        {currentStep.comp(handleChange, data)}
      </Container>
      <Footer
        currentStep={currentStep}
        onClickNext={onClickNext}
        onClickSave={onClickSave}
        onClickPrev={onClickPrev}
        onClickCancel={onClickCancel}
      />
    </>
  );
}
