import { fetchAPI, parseBody } from "./common";
import { image } from "./index";

async function uploadFiles(data) {
  if (!data.files) return [];

  let files = [];
  for (let i = 0; i < data.files?.length; i++) {
    const file = data.files[i];
    if (file && typeof file !== "string") {
      const url = await image.upload(file);
      files.push(url);
    }
  }

  return files;
}

export async function getClient() {
  const res = await fetchAPI(`/client/me`, "get");
  return parseBody(res);
}

export async function updatePersonalInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/personal/${id}`, "put", data);
  return parseBody(res);
}

export async function updateResidenceInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/residence/${id}`, "put", data);
  return parseBody(res);
}

export async function updateEmploymentInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/employment/${id}`, "put", data);
  return parseBody(res);
}

export async function updateSpouseInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/spouse/${id}`, "put", data);
  return parseBody(res);
}

export async function updateCorporationInfo(data) {
  if (data.files) {
    data.files = await uploadFiles(data);
  }

  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/corporation_fe/${id}`, "put", data);
  return parseBody(res);
}

export async function updateBeneficiaryInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/beneficiary/${id}`, "put", data);
  return parseBody(res);
}

export async function updateBalanceSheetInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/balance_sheet_fe/${id}`, "put", data);
  return parseBody(res);
}

export async function updateObjectivesInfo(data) {
  delete data.id;
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/objectives/${id}`, "put", data);
  return parseBody(res);
}

export async function updateUploadInfo(data) {
  delete data.id;
  const files = uploadFiles(data);

  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/uploads_fe/${id}`, "put", {
    files,
  });
  return parseBody(res);
}
